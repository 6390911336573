/**
 * @copyright @2022 Techechelons Infosolution Pvt. Ltd. All rights reserved.
 * @description Page to create loader component.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 17/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import { memo } from 'react';
import { GridLoader } from 'react-spinners';
import PropTypes from 'prop-types';
import { Box, useTheme } from '@mui/material';

/* Local Imports */
import styles from './index.style';

// ----------------------------------------------------------------------

/**
 * @typedef propTypes
 * @property {number} size
 * @property {object|function} containerStyle
 */
const propTypes = {
  size: PropTypes.number,
  containerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
};

/**
 * @typedef defaultProps
 * @property {number} size
 * @property {object|function} containerStyle
 */
const defaultProps = {
  size: 8,
  containerStyle: {}
};

/**
 * Component displays animated loading screen.
 *
 * @component
 * @param {number} size - size fo the loader
 * @param {object|function} containerStyle - custom styles
 */
function Loader({ size, containerStyle, ...other }) {
  /* Hooks */
  const theme = useTheme();

  /* Output */
  return (
    <Box
      data-testid="loader"
      sx={[styles.rootStyle, containerStyle]}
      {...other}
    >
      <GridLoader size={size} color={theme.palette.primary.main} />
    </Box>
  );
}

Loader.propTypes = propTypes;
Loader.defaultProps = defaultProps;
export default memo(Loader);
