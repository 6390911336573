/**
 * @copyright @2022 Techechelons Infosolution Pvt. Ltd. All rights reserved.
 * @description Page to create Header for admin dashboard pages.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 21/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Container, useTheme } from '@mui/material';
import WebsiteLogo from 'components/WebsiteLogo';

/* Local Imports */
import styles from '../index.style';

// ----------------------------------------------------------------------

/**
 * @typedef propTypes
 * @property {function} onMobileNavOpen
 */
const propTypes = {
  onMobileNavOpen: PropTypes.func.isRequired
};

// ----------------------------------------------------------------------

/**
 * Header for the admin dashboard pages
 *
 * @component
 * @param {function} onMobileNavOpen - callback function to handle open/close on click of side bar icon.
 * @returns {JSX.Element}
 */
function Header({ onMobileNavOpen }) {
  /* Hooks */
  const theme = useTheme();

  /* Output */
  return (
    <Container maxWidth={false} sx={styles.header}>
      <Box sx={styles.logoStyle}>
        <WebsiteLogo isIcon />
      </Box>
    </Container>
  );
}

Header.propTypes = propTypes;
export default memo(Header);
