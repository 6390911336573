/**
 * @copyright @2022 Techechelons Infosolution Pvt. Ltd. All rights reserved.
 * @description Page to create styles for user layout.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 21/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

export default {
  rootStyle: (theme) => ({
    backgroundColor: theme.palette.background.default,
    width: '100%',
    height: '100vh',
    position: 'relative'
  }),
  containerStyle: {
    height: 'calc(100% - 64px)',
    width: '100%',
    mt: 8
  },
  header: (theme) => ({
    width: '100%',
    height: 64,
    position: 'fixed',
    zIndex: 999,
    left: 0,
    top: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    paddingY: 1,
    boxShadow:
      theme.palette.mode === 'dark' ? theme.shadows[12] : theme.customShadows.z4
  }),
  logoStyle: (theme) => ({
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 48,
    mt: 0.5
  }),
  footer: (theme) => ({
    px: 3,
    [theme.breakpoints.down(900)]: {
      textAlign: 'center'
    }
  })
};
